'use strict';

(function($){
  var app = {

    init: function() {
      this.menu();
      this.slider();
      this.cookiesAccept();
      this.cookiesConfig();
      this.lazyframe();
      return false;
    },
    menu: function () {
      $(".nav-icon").click(function(){
          $(".bg-black-menu").toggleClass("open");
          $(".bg-black-menu").height($('body').height());
      });
      var obj = document.querySelectorAll('.nav-icon');
        for(var i = obj.length -1;i>=0;i--){
          var toggle = obj[i];
          toggleactive(toggle);
      }
      function toggleactive(toggle) {
        toggle.addEventListener("click",function() {
          
          if(this.classList.contains("active") === true) {
          this.classList.remove("active");
          }
          else {
          this.classList.add("active");
          }
        });
      }
    },
    sliderAutoplay : function (carousel)
    {
      var current = carousel.find('.slick-current');
      var cell = current.find('.slide');
      if(cell.find('video').html() === undefined)
      {
        carousel.slick('slickPlay')
      }
      else
      {
        carousel.slick('slickPause')
      }

    },
    playPauseVideo : function(slick,control, carousel)
    {
      var current = carousel.find('.slick-current');
      var cell = current.find('.slide');
      var video = cell.find('video');
      if(video.html() !== undefined)
      {
        if (control === "play" && !carousel.hasClass('carousel-mobile')) {
          video.get(0).play();
          video.on('ended', function() {
            carousel.slick('slickNext')
          })
        } else {
          video.get(0).pause();
        }
      }
    },
    slider: function () {
      // Slider Home
      var carousel = $('.slider-home');

      carousel.on("init", function(event, slick) {
        setTimeout(function() {
          app.sliderAutoplay(carousel);
          app.playPauseVideo(slick,'play', carousel);
        }, 1000);

      });

      carousel.on("beforeChange", function(event, slick, currentSlide, nextSlide) {
        app.playPauseVideo(slick,'pause', carousel);
      });

      carousel.on("afterChange", function(event, slick, currentSlide) {
        app.sliderAutoplay(carousel);
        app.playPauseVideo(slick,'play', carousel);
      });

      carousel.slick({
        autoplay: true,
        autoplaySpeed: 6000,
        speed: 600,
        lazyLoad: 'progressive',
        arrows: true,
        dots: false,
        prevArrow: '<div class="slick-nav prev-arrow"><i></i><svg><use xlink:href="#circle"></svg></div>',
        nextArrow: '<div class="slick-nav next-arrow"><i></i><svg><use xlink:href="#circle"></svg></div>',
      });

      // Galeria
      $('.slider-for').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade: true,
        asNavFor: '.slider-nav'
      });
      $('.slider-nav').slick({
        slidesToShow: 4,
        slidesToScroll: 1,
        asNavFor: '.slider-for',
        dots: false,
        focusOnSelect: true,
        prevArrow: '<div class="slick-nav prev-arrow"><i></i></div>',
        nextArrow: '<div class="slick-nav next-arrow"><i></i></div>',
      });
    },
    cookiesAccept: function () {
      var msgCookies = $('#cookies-msg');
      var btnAccept = $('.btn-cookies-accept');
      btnAccept.click(function (e){
        e.preventDefault();
        $.cookie(keyAnalyticCookies, '1', { expires: 7, path: '/' });
        $.cookie(keyMarketingCookies, '1', { expires: 7, path: '/' });
        $.cookie(keyRrssCookies, '1', { expires: 7, path: '/' });
        msgCookies.slideUp();
      })
    },
    cookiesConfig: function (){
      var configContainer = $('.cookies-configuration-block');
      var msgCookies = $('#cookies-msg');

      if(typeof configContainer.html() !== 'undefined')
      {
        var checkAll = configContainer.find('input#cookies-todas');
        var checkAnalytics = configContainer.find('input#cookies-analiticas');
        var checkMarketing = configContainer.find('input#cookies-marketing');
        var checkRrss = configContainer.find('input#cookies-rrss');
        var buttonSave = configContainer.find('.btn-save-config');
        var msgSuccess = configContainer.find('#text-success');
        var msgError = configContainer.find('#text-error');

        checkAll.click(function ()
        {
          if($(this).prop('checked'))
          {
            checkAnalytics.prop('checked',true);
            checkMarketing.prop('checked',true);
            checkRrss.prop('checked',true);
          }
          else
          {
            checkAnalytics.prop('checked',false);
            checkMarketing.prop('checked',false);
            checkRrss.prop('checked',false);
          }
        })
        buttonSave.click(function ()
        {
          msgSuccess.addClass('d-none');
          msgError.addClass('d-none');
          $.cookie(keyAnalyticCookies, ((checkAnalytics.prop('checked')) ? '1' : '0'), { expires: 7, path: '/' });
          $.cookie(keyMarketingCookies, ((checkMarketing.prop('checked')) ? '1' : '0'), { expires: 7, path: '/' });
          $.cookie(keyRrssCookies, ((checkRrss.prop('checked')) ? '1' : '0'), { expires: 7, path: '/' });
          if(typeof msgCookies.html() !== 'undefined') msgCookies.hide();
          msgSuccess.removeClass('d-none');
        })
      }
    },
    lazyframe: function () {
      lazyframe('.lazyframe', {
        apikey: 'AIzaSyBG22hzTpV5lxNFeYgNDuv1SSD_NBEAu9k', // cambiar esta API por la del proyecto https://github.com/vb/lazyframe#apikey
        debounce: 250,

        onAppend: (function (lazyframe) {
          return console.log(lazyframe.parentNode.classList.add("loaded"))
        })
      });
    },
  };
  $(function(){
    // var speed = 100;
    // function incEltNbr(id) {
    //   elt = document.getElementById(id);
    //   endNbr = Number(document.getElementById(id).innerHTML);
    //   incNbrRec(0, endNbr, elt);
    // }
    // function incNbrRec(i, endNbr, elt) {
    //   if (i <= endNbr) {
    //     elt.innerHTML = i;
    //     setTimeout(function() {
    //       incNbrRec(i + 1, endNbr, elt);
    //     }, speed);
    //   }
    // }
    // incEltNbr("nbr");
    
    app.init()
  });
})(jQuery);